import { getDefaultStore } from "jotai";
import {
  CountryCode,
  PhoneNumber,
  formatNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { isValidPhoneNumber } from "..";
import {
  cleanPhoneNumber,
  isPhoneNumberAGroupContributionId,
  isSamePhoneNumber,
} from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { findConversationByPhoneNumber } from "../messaging/conversation/findConversationByPhoneNumber";
import { atoms } from "./atoms";
import { isChatbot } from "./chatbots";
import { checkAndRefetchConfig, getConfig } from "./config";
import { getUserLocationCountry } from "./localstorage";
import { handleSessionExpired } from "./loginAndCaps/session";

/**
 * This should be called anytime we need to format a phone number for the network or the UI.
 * The logic behind this:
 * 1 - Whenever an international number is provided (+ or 00), we just assume it is valid and continue to step 3
 * 2 - When the format is national, we have the following:
 *  1 - Use the local country to format it. If number is valid, we keep it.
 *  2 - If using the local country don't return a good number, check on local caps if we can match an existing phone number.
 *  3 - If nothing found, try to match from an existing conversation
 *  4 - Continue with whatever is found at this point
 * 3 - Format according to the format parameter:
 *  - "E164": number in international format with only + and digits
 *  - "E123": human readable number in either international or national depending on the original input (If "+" or "00" provided originally, international, if not, national)
 *  - "SIP": e164 format with the sip and domain
 * 4 - On the best case, number will come back well formatted. If not, application cannot do much and will return the original input.
 */
function getDomain(config) {
  const domain =
    config["application/3gpp_ims/home_network_domain_name"] ??
    config["application/appauth/realm"];
  if (domain) return domain;
  const username =
    config["application/3gpp_ims/ext/gsma/username"] ??
    config["application/appauth/username"];
  return username.substr(username.indexOf("@") + 1);
}
export function formatPhoneNumber(
  phoneNumber: string,
  format: "E164" | "E123"
): string;
export function formatPhoneNumber(
  phoneNumber: string,
  format: "SIP"
): Promise<string>;
export function formatPhoneNumber(
  phoneNumber?: string,
  format: "E164" | "E123" | "SIP" = "E164"
): string | Promise<string> {
  if (!phoneNumber) {
    return "";
  }
  // No formatting for group chats and chatbots
  if (
    isChatbot(phoneNumber) ||
    isPhoneNumberAGroupContributionId(phoneNumber)
  ) {
    return phoneNumber;
  }

  // Clean first for below check in case of sip/tel format provided
  phoneNumber = cleanPhoneNumber(phoneNumber);

  const originalInputClean = phoneNumber;

  const isNationalNumber =
    !phoneNumber.startsWith("00") && !phoneNumber.startsWith("+");

  // Get capabilities will actually format the number and get the most adapted phone known from the network
  phoneNumber = getCapabilities(phoneNumber).phoneNumber;

  // Nothing found, try to get it from the conversation which means network knows this number
  if (!phoneNumber.startsWith("+")) {
    phoneNumber = findConversationByPhoneNumber(phoneNumber)?.id || phoneNumber;
  }

  switch (format) {
    case "E164":
      return phoneNumber;
    case "E123":
      const phoneNumberFormatted =
        formatNumber(
          phoneNumber,
          isNationalNumber ? "NATIONAL" : "INTERNATIONAL"
        ) || phoneNumber;

      /**
       * Special case:
       * Maybe user added originally the international information without the + like 15145550101 instead of +15145550101
       * In this case, we still want the international format
       */
      if (
        isNationalNumber &&
        originalInputClean !== cleanPhoneNumber(phoneNumberFormatted)
      ) {
        return formatNumber(phoneNumber, "INTERNATIONAL") || phoneNumber;
      }

      return phoneNumberFormatted;
    case "SIP":
      // TODO maybe this is the wrong place for this. maybe use `handleReloginMechanic`
      return getConfig().then((config) => {
        if (!config) {
          return checkAndRefetchConfig().then((newConfig) => {
            if (!newConfig) {
              handleSessionExpired();
              // TODO do something like logout
              throw new Error("Failed to fetch config even after retrying");
            }

            const domain = getDomain(newConfig);
            const remote = `sip:${phoneNumber}@${domain}`;
            return remote;
          });
        }

        if (!phoneNumber) {
          console.error("unable to parse phone number", phoneNumber);
          return "";
        }

        const domain = getDomain(config);
        const remote = `sip:${phoneNumber}@${domain}`;
        return remote;
      });
  }
}
const getCapabilities = (phoneNumber?: string) => {
  phoneNumber = _formatPhoneNumber(phoneNumber);

  if (
    isChatbot(phoneNumber) ||
    isPhoneNumberAGroupContributionId(phoneNumber)
  ) {
    return { phoneNumber, capabilities: [] };
  }

  const capabilitiesPerNumber = getDefaultStore().get(
    atoms.capabilities.capabilities
  );

  let capabilities = capabilitiesPerNumber[phoneNumber];

  // Fallback, check if caps exist for a number with + (non + numbers can never have caps)
  if (!phoneNumber.startsWith("+") && isValidPhoneNumber(phoneNumber)) {
    const key = Object.keys(capabilitiesPerNumber).find(
      (key) => key.startsWith("+") && isSamePhoneNumber(key, phoneNumber)
    );
    if (key) {
      phoneNumber = key;
      capabilities = capabilitiesPerNumber[key];
    }
  }

  return { phoneNumber, capabilities };
};
function _formatPhoneNumber(
  phoneNumber?: string,
  format: "E164" | "E123" = "E164"
) {
  if (
    !phoneNumber ||
    isPhoneNumberAGroupContributionId(phoneNumber) ||
    isChatbot(phoneNumber)
  ) {
    return phoneNumber || "";
  }

  let cleanedNumber = cleanPhoneNumber(phoneNumber);

  if (cleanedNumber.startsWith("00")) {
    cleanedNumber = "+" + cleanedNumber.slice(2);
  }

  // Return international format directly
  if (cleanedNumber.startsWith("+")) {
    cleanedNumber =
      formatNumber(cleanedNumber, "INTERNATIONAL") || cleanedNumber;
    return format === "E164" ? cleanPhoneNumber(cleanedNumber) : cleanedNumber;
  }

  const country = getUserLocationCountry();

  // Try to use local country to build the number
  let parsedNumber: undefined | PhoneNumber;
  try {
    parsedNumber = parsePhoneNumber(
      cleanedNumber,
      country.country_iso_code as CountryCode
    ) as PhoneNumber;
  } catch (e) {}

  // If failed, revert back to original number
  if (!parsedNumber || !parsedNumber.isValid()) {
    return phoneNumber;
  }

  return format === "E164"
    ? cleanPhoneNumber(parsedNumber.number)
    : // Always return national since the number was provided without the international code
      formatNumber(parsedNumber.number.toString(), "NATIONAL");
}
