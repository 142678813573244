import {
  GroupChatInfos,
  GroupChatParticipant,
  ReferenceTypes,
  Reply,
} from "@/types/messaging";
import { nanoid } from "nanoid";
import { proxy, ref } from "valtio";
import {
  getContributionIdFromMessageResourceUrl,
  getTextFromRichCard,
  getTextFromSuggestionResponse,
  sendMessageStatus,
} from ".";
import { proxyToRaw } from "..";
import { SuggestionResponse } from "../../components/chatScreen/chat/typings";
import { ChatbotPayload } from "../../components/chatScreen/chat/typings/gsma";
import { CallLogPayload } from "../../components/recentCallScreen/CallHistory";
import {
  ChangedObject,
  DeletedObject,
  FlagList,
  ImdnInfo,
  ObjectItem,
  OmaNmsSchema,
} from "../../types/OmaNms";
import { callsState } from "../calls/callState";
import { getLoadedContacts } from "../contacts/contactUtils";
import { deleteStoredCall, deleteStoredConversation } from "../database";
import { delay } from "../helpers/Utils";
import WebGwContact from "../helpers/WebGwContact";
import { getLoadedChatbots } from "../helpers/chatbots";
import { getLocalUser } from "../helpers/localstorage";
import { NewMessageNotification } from "../helpers/notificationChannel";
import { conversationsState } from "./conversation/ConversationState";
import {
  cleanPhoneNumber,
  getTextAfterLastSlash,
  isSamePhoneNumber,
} from "./conversation/conversationUtils/phoneNumberUtils";
import { updateConversationInDatabase } from "./conversation/conversationUtils/updateConversationInDatabase";
import { findConversationByPhoneNumber } from "./conversation/findConversationByPhoneNumber";

type Cache = {
  text?: string | null;
  contact?: WebGwContact | null;
};

const cacheMap: Record<string, Cache> = {};

export default interface NmsMessage {
  "imdn.Message-ID": string;
  Date: Date;
  payloadParts: NonNullable<ObjectItem["payloadPart"]>;
  "Content-Type"?: string | string[];
  UserId?: string;
  To: string;
  From: string;
  Direction?: "In" | "Out";
  "Reference-Type"?: ReferenceTypes;
  "Reference-ID"?: string;
  /**
   * @deprecated if not for conference info, get the text content from payloadParts
   */
  TextContent?: string[];
  "Conversation-ID"?: string;
  "Contribution-ID"?: string;
  ConferenceUri?: string;
  "Message-Context"?: string;
  "P-Asserted-Service"?: string;
  imdns?: ObjectItem["imdns"];
  flags?: ObjectItem["flags"];
  uploadProgress?: number;
  FileName?: string;
  reactKeyIdList: string; // This is used for the key prop on react list. Since "imdn.Message-ID" can change (temp local waiting for server one), this will avoid unnecessary re-renders.
  ObjectId: string;
  reactions?: ObjectItem["reactions"];
  deleted: boolean;
  history: NmsMessage[];
  [x: string]: unknown;
}
export default class NmsMessage {
  /** Meant for UI purposes. Does not reflect the state of a message */
  public _isBeingDeleted = false;

  constructor(object?: any) {
    this.construct();

    if (object !== false) {
      if (object?.payloadPart) {
        this.createObject(object);
      } else if (
        object?.changedObject ||
        object?.deletedObject ||
        object === undefined
      ) {
        console.info(
          "Creating empty object due to async requirements or the message is outgoing"
        );
      } else {
        console.error("NMS message type not supported:", object);
      }
    }

    return ref(proxy(this));
  }

  private construct() {
    this.reactKeyIdList = nanoid();

    if (!this.flags) {
      this.flags = { flag: [] };
    }

    if (!this.imdns) {
      this.imdns = { imdn: [] };
    }

    if (!this.payloadParts) {
      this.payloadParts = [];
    }

    if (!this.reactions) {
      this.reactions = {};
    }

    if (!this.history) {
      this.history = [];
    }
  }

  destruct() {
    delete cacheMap[this["imdn.Message-ID"]];
  }

  private get cache() {
    return (cacheMap[this["imdn.Message-ID"]] ??= {});
  }

  createObject(object?: ObjectItem) {
    if (!object?.attributes?.attribute) return;

    for (const attr of object.attributes.attribute) {
      this[attr.name] = attr.value; // if there are duplicate attributes, the last value will be used
    }

    if (Array.isArray(this.To)) {
      this.To = this.To[0];
    }
    if (!this.To) {
      console.error("Nms message doesn't have a To attribute", object);
      throw new Error("Nms message doesn't have a To attribute");
    }
    if (Array.isArray(this.From)) {
      this.From = this.From[0];
    }
    if (!this.From) {
      console.error("Nms message doesn't have a From attribute", object);
      throw new Error("Nms message doesn't have a From attribute");
    }

    this.Date &&= new Date(this.Date);

    if (!object.payloadPart) {
      console.error("Invalid NMS message...", object);
      throw new Error("Invalid NMS message");
    }

    this.imdns = object.imdns;
    this.flags = object.flags;
    this.payloadParts = object.payloadPart;
    this.reactions = object.reactions;

    this.ObjectId = getTextAfterLastSlash(object?.resourceURL);
    console.warn("Received NMS object with objectId", this.ObjectId);
  }

  async createChangedObject(object?: ChangedObject) {
    if (!object?.attributes?.attribute) return;

    for (const attr of object.attributes.attribute) {
      this[attr.name] = attr.value; // if there are duplicate attributes, the last value will be used
    }

    if (Array.isArray(this.To)) {
      this.To = this.To[0];
    }
    if (!this.To) {
      console.error("Nms message doesn't have a To attribute", object);
      throw new Error("Nms message doesn't have a To attribute");
    }
    if (Array.isArray(this.From)) {
      this.From = this.From[0];
    }
    if (!this.From) {
      console.error("Nms message doesn't have a From attribute", object);
      throw new Error("Nms message doesn't have a From attribute");
    }

    this.Date &&= new Date(this.Date);

    this.imdns = object.imdns;
    this.flags = object.flags;

    this.ObjectId = getTextAfterLastSlash(object?.resourceURL);

    const isGroupChatInfos = () => {
      return (
        !!this["Contribution-ID"] &&
        this["Content-Type"] === "application/conference-info+xml"
      );
    };

    const fetchPayloadParts = async () => {
      const objectResult = await fetch(object?.resourceURL, {
        method: "GET",
        credentials: "include",
      });
      if (objectResult.ok) {
        const jsonResult = await objectResult.json();
        this.payloadParts = jsonResult?.object?.payloadPart;
      }
    };

    // Only fetch the payload parts if not there yet
    if (!object.payloadPart || object.payloadPart.length === 0) {
      await delay(2000);

      if (object && isGroupChatInfos()) {
        console.log("not fetching " + object?.resourceURL);
      } else if (!object.payloadPart || object.payloadPart.length === 0) {
        await fetchPayloadParts();
      } else {
        this.payloadParts = object.payloadPart;
      }
    } else {
      this.payloadParts = object.payloadPart;
    }
  }

  public isUploading() {
    return (
      !!this.uploadProgress &&
      this.uploadProgress > 0 &&
      this.uploadProgress < 100
    );
  }

  async createDeletedObject(object?: DeletedObject) {
    if (!object?.attributes?.attribute) return;

    for (const attr of object.attributes.attribute) {
      this[attr.name] = attr.value; // if there are duplicate attributes, the last value will be used
    }

    if (Array.isArray(this.To)) {
      this.To = this.To[0];
    }
    if (!this.To) {
      console.error("Nms message doesn't have a To attribute", object);
      throw new Error("Nms message doesn't have a To attribute");
    }
    if (Array.isArray(this.From)) {
      this.From = this.From[0];
    }
    if (!this.From) {
      console.error("Nms message doesn't have a From attribute", object);
      throw new Error("Nms message doesn't have a From attribute");
    }

    this.Date &&= new Date(this.Date);
    this.ObjectId = getTextAfterLastSlash(object?.resourceURL);
  }

  private setContact(contact: WebGwContact) {
    return (this.cache.contact = contact);
  }
  public contact() {
    if (this.cache.contact) return this.cache.contact;
    const contacts = getLoadedContacts(); // ! the contacts need to be prefetched for this to work
    if (contacts) {
      for (const contact of contacts) {
        if (
          contact
            .getAllPhoneNumbers()
            .some((remoteAddress) =>
              isSamePhoneNumber(remoteAddress, this.From)
            )
        ) {
          return this.setContact(contact);
        }
      }
    } else {
      console.warn("Contacts are not yet loaded!");
    }

    const [chatbots] = getLoadedChatbots() ?? []; // ! the chatbots need to be prefetched for this to work

    if (chatbots) {
      for (const chatbot of chatbots) {
        if (chatbot.id.includes(this.From)) {
          return this.setContact(WebGwContact.fromChatbotInfo(chatbot));
        }
      }
    } else {
      console.warn("Chatbots are not yet loaded!");
    }
  }

  public get isDisplayedNotificationSent() {
    return !!this.flags?.flag?.includes("\\Seen");
  }

  public get isAnswered() {
    return this.flags?.flag?.includes("\\Answered");
  }

  public static from(obj: any) {
    const msg = new NmsMessage(false);
    Object.assign(msg, obj);
    return msg;
  }

  public merge(obj: Partial<NmsMessage>) {
    if (obj.imdns?.imdn && this.imdns?.imdn) {
      obj.imdns.imdn.unshift(...this.imdns.imdn);
    }
    if (obj.flags?.flag && this.flags?.flag) {
      obj.flags?.flag.unshift(...this.flags?.flag);
    }
    if (obj.history && this.history) {
      // History is a list of NmsMessage, convert them to raw object to avoid circular dependency when assign below
      obj.history = proxyToRaw(obj.history);

      /**
       * Discard recursive history, not needed, avoids having structure:
       * - message
       * - message Edit 1 -> history[message]
       * - message Edit 2 -> history[message, message Edit 1[history[message]]]
       */
      obj.history.forEach((msg) => (msg.history = []));

      /**
       * Will be in the end:
       * - message
       * - message Edit 1 -> history[message]
       * - message Edit 2 -> history[message, message Edit 1]
       */
      obj.history.unshift(...proxyToRaw(this.history));
    }

    if (obj.reactions) {
      if (!this.reactions) {
        this.reactions = {};
      }

      Object.entries(obj.reactions).forEach((reaction) => {
        const emoji = reaction[0];
        const reactions = reaction[1];
        const reactionMessageIds = reactions.map(
          (reaction) => reaction["imdn.Message-ID"]
        );
        const storedEmoji = this.reactions?.[emoji];
        const storedReactions = storedEmoji || [];
        const storedReactionMessageIds = storedReactions.map(
          (message) => message["imdn.Message-ID"]
        );

        // Avoid duplicate reaction messages, particularly with nms returning messages already received
        if (
          !reactionMessageIds.some((messageId) =>
            storedReactionMessageIds.includes(messageId)
          )
        ) {
          storedReactions.push(...reactions);

          if (!storedEmoji) {
            this.reactions![emoji] = storedReactions;
          }
        }
      });

      obj.reactions = this.reactions;
    }

    return Object.assign(this, obj);
  }

  public setFlag(flag: NonNullable<FlagList["flag"]>[number]) {
    this.flags ??= {};
    this.flags.flag ??= [];
    if (!this.flags.flag.includes(flag)) {
      this.flags.flag!.push(flag);
    }
  }

  public setImdn(imdn: ImdnInfo["type"]) {
    this.imdns ??= {};
    this.imdns.imdn ??= [];
    const now = new Date().toISOString();
    this.imdns.imdn.push({
      imdnInfo: [{ date: now, type: imdn.trim() as typeof imdn }],
      originalTo: this.From,
    });
  }

  public async sendDisplayed() {
    if (this.isDisplayedNotificationSent) {
      return;
    }

    await sendMessageStatus(
      this.From,
      this.getOriginalMessageId(),
      "Displayed"
    );

    this.setFlag("\\Seen");

    const conversation = findConversationByPhoneNumber(this.From);
    if (conversation) {
      updateConversationInDatabase(conversation.id);
    }
  }

  /**
   * A message could have an history (if edited) but clients will always use the message id from the original message when doing network operations like sending imdn, edit, etc.
   * Always use this method to get the id to use for network
   */
  public getOriginalMessageId() {
    // Consider first the original message or the current one if no edit yet
    return this.history.length > 0
      ? this.history[0]["imdn.Message-ID"]
      : this["imdn.Message-ID"];
  }

  /**
   * This method checks if a message contains the provided message id, either its own message id or original message id in case of edit
   */
  public hasMessageId(messageId: string, lookupHistory = true) {
    return (
      this["imdn.Message-ID"] === messageId ||
      (lookupHistory &&
        this.history.length > 0 &&
        this.history[0]["imdn.Message-ID"] === messageId)
    );
  }

  public static fromWebgwNotification(msg: NewMessageNotification) {
    const nmsMsg = new NmsMessage();
    nmsMsg["imdn.Message-ID"] = getTextAfterLastSlash(
      msg.chatMessage.resourceURL
    );
    // nmsMsg.TextContent = [message.chatMessageNotification.chatMessage.text];
    nmsMsg["Content-Type"] = msg.chatMessage.contentType;
    nmsMsg["Reference-Type"] = msg.chatMessage.referenceType;
    nmsMsg["Reference-ID"] = msg.chatMessage.referenceId;
    nmsMsg.Date = new Date(msg.dateTime);
    // nmsMessage["Message-Context"] = "message/onetoone";
    nmsMsg.Direction = "In";
    nmsMsg.UserId = msg.senderAddress;
    nmsMsg.From = msg.senderAddress;
    const localUser = getLocalUser();
    if (!localUser) {
      throw new Error("No local user");
    }
    nmsMsg.To = localUser;

    nmsMsg["Contribution-ID"] = getContributionIdFromMessageResourceUrl(
      msg.chatMessage.resourceURL,
      true
    );
    console.warn("Content-Type:", msg.chatMessage.contentType);
    if (
      msg.chatMessage.contentType === "application/vnd.gsma.rcs-ft-http+xml"
    ) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(
        msg.chatMessage.text,
        "application/xml"
      );
      const thumbnailElement = xmlDoc.querySelector(
        'file-info[type="thumbnail"]'
      );
      const fileElement = xmlDoc.querySelector('file-info[type="file"]');

      const thumbnailUrls = thumbnailElement
        ?.querySelector("data")
        ?.getAttribute("url");
      const thumbnailContentType =
        thumbnailElement?.querySelector("content-type")?.textContent;

      const fileUrls = fileElement?.querySelector("data")?.getAttribute("url");
      const fileContentType =
        fileElement?.querySelector("content-type")?.textContent;

      const fileName = fileElement?.querySelector("file-name")?.textContent;
      const fileSize = fileElement?.querySelector("file-size")?.textContent;

      console.log("Thumbnail URLs:", thumbnailUrls);
      console.log("File URLs:", fileUrls);

      if (thumbnailUrls) {
        nmsMsg.payloadParts.push({
          contentDisposition: "icon",
          contentType: thumbnailContentType ?? "",
          href: thumbnailUrls ?? "",
        });
      }

      if (fileUrls) {
        nmsMsg.payloadParts.push({
          contentDisposition: "file",
          contentType: fileContentType ?? "",
          href: fileUrls ?? "",
          size: fileSize ? parseFloat(fileSize) : undefined,
        });

        nmsMsg.FileName = fileName ?? "";
      }
    } else {
      nmsMsg.payloadParts = [
        {
          contentType: msg.chatMessage.contentType,
          textContent: msg.chatMessage.text,
          href: msg.chatMessage.resourceURL,
        },
      ];
    }

    for (const imdn of msg.chatMessage.reportRequest.split(",")) {
      nmsMsg.setImdn(imdn as ImdnInfo["type"]);
    }

    return nmsMsg;
  }

  public static fromTextMessage(
    message: string,
    from: string,
    to: string,
    reply?: Reply
  ) {
    const nmsMsg = new NmsMessage();
    nmsMsg["imdn.Message-ID"] = nanoid();
    // TextContent is not used across the app, rather the payloadParts are used for text
    // nmsMsg.TextContent = [message];
    nmsMsg["Content-Type"] = "text/plain";
    nmsMsg["Reference-Type"] = reply?.type;
    nmsMsg["Reference-ID"] = reply?.id;
    nmsMsg.Date = new Date();
    // nmsMessage["Message-Context"] = "message/onetoone";
    nmsMsg.Direction = "Out";
    nmsMsg.UserId = from;
    nmsMsg.From = from;
    nmsMsg.To = to;
    nmsMsg.payloadParts = [
      {
        contentType: "text/plain",
        textContent: message,
      },
    ];
    return nmsMsg;
  }

  public static toMsgList(obj: any[]) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = NmsMessage.from(obj[i]);
    }
    return obj as NmsMessage[];
  }

  public static toListFromOmaNmsSchema(nmsObj: OmaNmsSchema) {
    if (!nmsObj?.objectList?.object) {
      return;
    }
    const msgList = [] as NmsMessage[];
    for (const obj of nmsObj.objectList.object) {
      if (obj) msgList.push(new NmsMessage(obj));
    }
    return msgList;
  }

  public static async fromNmsWebsocketEvent(nmsEvent: OmaNmsSchema) {
    if (!nmsEvent?.nmsEventList?.nmsEvent) {
      return;
    }
    const msgList = [] as NmsMessage[];
    for (const obj of nmsEvent?.nmsEventList?.nmsEvent) {
      if (obj.changedObject) {
        const nmsMessage = new NmsMessage(obj);
        await nmsMessage.createChangedObject(
          obj.changedObject as ChangedObject
        );
        msgList.push(nmsMessage);
      } else if (obj.deletedObject) {
        const nmsMessage = new NmsMessage(obj);
        await nmsMessage.createDeletedObject(
          obj.deletedObject as DeletedObject
        );

        const foundConversation = findConversationByPhoneNumber(
          nmsMessage.isGroupChat()
            ? nmsMessage["Contribution-ID"]!
            : nmsMessage.Direction === "In"
              ? nmsMessage.From
              : nmsMessage.To || ""
        );
        console.log("Found conversation -> ", foundConversation);
        if (foundConversation) {
          console.log("Removing msgid:", nmsMessage["imdn.Message-ID"]);
          foundConversation.removeMessage(nmsMessage);
        }

        if (foundConversation?.getMessages().length === 0) {
          // Only delete the current UI + from the DB
          conversationsState.conversations.delete(foundConversation.id);
          deleteStoredConversation(foundConversation);
        }

        if (nmsMessage["Message-Context"] === "message/callhistory") {
          // Only delete the current UI + from the DB
          const deleted = callsState.calls.delete(
            nmsMessage["imdn.Message-ID"]
          );
          console.log("Deleted call with key", nmsMessage["imdn.Message-ID"]);
          deleteStoredCall(nmsMessage["imdn.Message-ID"]);
        }
      }
    }
    return msgList;
  }

  private setText(text: string | null) {
    this.cache.text = text;
    return text;
  }
  public getText() {
    if (this.cache.text != null) {
      return this.cache.text as ReturnType<typeof this.setText>;
    }

    // Conference info does not come in the payloads
    if (
      this["Content-Type"] === "application/conference-info+xml" &&
      (this.TextContent as string | undefined)
    ) {
      return this.setText(this.TextContent as unknown as string);
    }

    for (const part of this.payloadParts) {
      if (!part.textContent) continue;
      if (part.contentType === "application/vnd.gsma.botsuggestion.v1.0+json")
        continue;
      try {
        switch (part.contentType) {
          case "application/vnd.gsma.botsuggestion.response.v1.0+json":
            return this.setText(
              getTextFromSuggestionResponse(
                JSON.parse(part.textContent) as SuggestionResponse
              ) ?? part.textContent
            );
          case "application/vnd.gsma.rcspushlocation+xml":
            const latLng = part.textContent.match(
              /<gml:pos>(.*) (.*)<\/gml:pos>/
            );
            return this.setText(
              latLng?.length == 3
                ? JSON.stringify({ lat: latLng[1], lng: latLng[2] })
                : part.textContent
            );
          case "application/vnd.gsma.botmessage.v1.0+json":
            return this.setText(
              getTextFromRichCard(
                JSON.parse(part.textContent) as ChatbotPayload
              ) ?? part.textContent
            );
          case "application/vnd.call-history+json":
            this.setCallLog(JSON.parse(part.textContent));
            break;
          default:
            let text = null;
            if (part.contentType.includes("multipart/mixed;")) {
              const extractedJSON = part.textContent.match(/{"suggestion.*}/s);
              if (extractedJSON) {
                const suggestion = JSON.parse(extractedJSON[0]).suggestions[0];
                text =
                  suggestion.reply?.displayText ??
                  suggestion.action?.displayText;
              }
            }
            return this.setText(text) ?? part.textContent;
        }
      } catch {
        console.error("Unable to parse", part.textContent);
        return this.setText(part.textContent);
      }
    }
    return this.setText(null);
  }

  // // private _callLog?: CallLogPayload | null;
  private setCallLog(ch: CallLogPayload | null) {
    this._callLog = ch;
    return ch;
  }
  public getCallLog() {
    if (this._callLog)
      return this._callLog as ReturnType<typeof this.setCallLog>;

    for (const part of this.payloadParts || []) {
      if (part.textContent) {
        if (
          part.contentType === "application/vnd.gsma.botsuggestion.v1.0+json"
        ) {
          continue;
        }

        if (part.contentType === "application/vnd.call-history+json") {
          try {
            // TODO: Parse the new payload part for VoiceBot
            const res = JSON.parse(part.textContent);
            return this.setCallLog(res);
          } catch {
            console.error("Unable to parse into callLog", part.textContent);
          }
        }
      }
    }
    return this.setCallLog(null);
  }

  public getSenderAddress() {
    if (this.Direction === "In") {
      return this.From;
    }
    return this.To;
  }

  public getGroupChatInfos(): GroupChatInfos | undefined {
    if (this.isGroupChatInfos()) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(
        this.getText() || "",
        "application/xml"
      );

      const subject = xmlDoc.querySelector("subject")?.textContent;
      const iconUri = xmlDoc.querySelector("icon-uri")?.textContent;
      const users = xmlDoc.querySelector("users");

      const participants: GroupChatParticipant[] = [];

      users?.childNodes.forEach((user) => {
        const endpointElement = (user as Element).querySelector(
          "endpoint"
        ) as Element;
        const rolesElement = (user as Element).querySelector(
          "roles"
        ) as Element;

        const phoneNumberElement = endpointElement.getAttribute("entity");
        const isJoined =
          endpointElement.querySelector("status")?.textContent !== "departed";
        const isAdmin =
          rolesElement.querySelector("entry")?.textContent === "Administrator";

        if (phoneNumberElement) {
          participants.push({
            phoneNumber: cleanPhoneNumber(phoneNumberElement),
            isJoined,
            isAdmin,
          });
        }
      });

      const groupChatInfos = {
        iconUrl: iconUri || undefined,
        subject: subject || undefined,
        participants: participants,
        date: this.Date,
      };

      console.log(
        "Group chat infos for ",
        this["Contribution-ID"],
        " ",
        groupChatInfos
      );

      return groupChatInfos;
    }

    return undefined;
  }

  public isGroupChatInfos(): boolean {
    return (
      this.isGroupChat() &&
      this["Content-Type"] === "application/conference-info+xml"
    );
  }

  public isGroupChat(): boolean {
    return !!this["Contribution-ID"];
  }

  public getReactionType(): "ADD" | "REMOVE" | undefined {
    if (
      typeof this["Reference-Type"] === "string" &&
      this["Reference-Type"].includes("Reaction")
    ) {
      return this["Reference-Type"].startsWith("+") ? "ADD" : "REMOVE";
    }
  }
}
